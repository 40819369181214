import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";

import { ContentBlock } from "../../components/Blocks";
import { TextBlock } from "../../components/Blocks";
import Pages from "../../components/Pages";
import seoData from "../../components/SEO/data";
import { removePreloader, scriptLoader } from "../../helpers";
import { AuthContext } from "../../widgets";

export default function Store({ location }) {
	const [isMount, setIsMount] = useState(false);
	const [token, setToken] = useState(null);
	const [node, setNode] = useState({ remove() {
		console.warn("Empty remove method");
	} });

	const { signCookie } = useContext(AuthContext);

	function initBrowser() {
		if (typeof xProductBrowser !== "undefined" && !isMount) {
			// eslint-disable-next-line
			xProductBrowser(
				"categoriesPerRow=3",
				"views=grid(20,3) list(60) table(60)",
				"categoryView=grid",
				"searchView=list",
				"id=my-store-25582026",
			);

			// eslint-disable-next-line
			Ecwid.OnAPILoaded.add(() => {
				fetch(
					"https://serafim.help/api/shops/ecwid/sso_token",
					{
						method: "GET",
						credentials: "include",
					},
				)
					.then(data => data.text())
					.then(result => setToken(result))
					.catch(error => console.error(error));
			});

			setIsMount(true);
			window.ecwid_dynamic_widgets = true;
		}
	}

	function init() {
		if (process.env.GATSBY_SHOP_ENABLE && !isMount) {
			scriptLoader("https://code.jquery.com/jquery-3.4.1.min.js");
			const script = scriptLoader("https://app.ecwid.com/script.js?25582026&data_platform=code&data_date=2020-03-10", initBrowser);

			script.id = "ecwid-script";

			setNode(script);
		}
	}

	useEffect(() => {
		signCookie(() => setIsMount(true));
	}, []);

	useEffect(() => {
		removePreloader();
	}, []);

	useEffect(() => {
		// FIXME: Need to remove crutch below
		if (typeof location !== "undefined" && typeof Ecwid !== "undefined" && isMount) {
			if (/cart/g.test(location.hash)) {
				// eslint-disable-next-line no-undef
				Ecwid.openPage("cart");
			}
			if (/accountSettings/g.test(location.hash)) {
				// eslint-disable-next-line no-undef
				Ecwid.openPage("account/settings");
			}
		}
	}, [location]);

	useEffect(() => {
		const ecwid = document.getElementById("ecwid-script");

		if (ecwid) {
			initBrowser();
		} else {
			init();
		}

		return () => node.remove();
	}, [isMount]);

	useEffect(() => {
		if (typeof Ecwid !== "undefined") {
			// eslint-disable-next-line
			Ecwid.setSsoProfile(token);
		}
	}, [token]);

	return (
		<Pages entity={get(seoData, "store_page", {})}>
			<ContentBlock>
				<div className={"container"}>
					<TextBlock title={"Церковная лавка"}>
						<div id="my-store-25582026"></div>
					</TextBlock>
				</div>
			</ContentBlock>
		</Pages>
	);
}

Store.propTypes = {
	location: PropTypes.object,
};
